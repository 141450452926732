<template>
  <div>
    <div class="container m-auto">
      <the-header />
      <div class="flex items-center justify-between p-4">
        <router-view />
      </div>
      <div class="grid grid-cols-1 gap-3 p-4 sm:grid-cols-1">
        <div
          class="flex items-center p-6 mb-3 text-white border rounded-lg shadow-lg justify-items-center border-gray callout"
        >
          Helping you share your passion for the trail.
        </div>
      </div>
      <footer class="px-4 py-8">
        <div>&copy; 2021 Trail.Pictures</div>
      </footer>
    </div>
  </div>
</template>

<script>
import global from "./global.js";
import useStore from "@/store";
import { useRouter, useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";
import firebase from "firebase";
import { isAuthenticated, logout } from "./firebase";
import TheHeader from "@/components/TheHeader.vue";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { state } = useStore();
    const user = ref({});

    return {
      global,
      user,
      logout,
      isAuthenticated,
      TheHeader,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #777;

  input,
  select,
  textarea {
    color: #000000;
  }

  input[type="submit"] {
    color: inherit;
  }
}

#nav {
  a {
    margin-right: 10px;
    font-weight: normal;
    color: #047857;

    &.router-link-exact-active {
      color: #e9a025;
    }
  }
}
footer,
header,
.info {
  display: flex;
  justify-content: space-between;
}
.socialicons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.contactus {
  color: #88bfe8;
}
.callout {
  background-color: #047857;
}
.sm-scr-only {
  @media screen and (max-width: 767px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.lg-scr-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>

<template>
  <div class="bg-opacity-15 borderbox">
    <slot></slot>
  </div>
</template>

<style scoped>
.borderbox {
  /* margin: 1.3rem auto; */
  height: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
.bg-opacity-15 {
  background-color: rgba(255, 255, 255, 0.15);
}
</style>
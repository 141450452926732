import { reactive, readonly } from "vue"

const state = reactive({
  siteName: "Experimenting with Composition API",
  count: 42,
  surveys: [],
  isLoggedIn: false,
})

const increment = function() {
  state.count++
}

const updateLoggedIn = function(status) {
  state.isLoggedIn = status
  console.log("isLoggedIn set to:", status)
}

const getSurveys = async () => {
  try {
    const surveyResponse = await fetch(
      "https://dci-digital-team.firebaseio.com/surveys.json"
    )
    const surveyJson = await surveyResponse.json()
    const surveyArray = []
    for (const id in surveyJson) {
      surveyArray.push({
        id: id,
        name: surveyJson[id].name,
        rating: surveyJson[id].rating,
      })
    }
    state.surveys = surveyArray
  } catch (e) {
    console.log("Error loading surveys:", e)
  }
}

getSurveys()

export default { state: readonly(state), increment, getSurveys, updateLoggedIn }

import { createRouter, createWebHistory } from "vue-router"
import { db, isAuthenticated } from "../firebase.js"

const routes = [
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/trails",
    name: "Trails",
    component: () => import("../views/Trails.vue"),
    async beforeEnter(to, from, next) {
      if (isAuthenticated === "unauthorized") {
        next()
      } else {
        window.location = "/admin"
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    async beforeEnter(to, from, next) {
      if (isAuthenticated === "unauthorized") {
        next()
      } else {
        window.location = "/admin"
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

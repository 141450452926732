<template>
  <button
    class="px-5 py-2 text-white uppercase rounded-lg cursor-pointer bg-gradient-to-b from-gray-500 to-gray-400 hover:from-gray-400 hover:to-gray-300 active:from-gray-400 active:to-gray-300"
  >
    <slot></slot>
  </button>
</template>

<style scoped>
/* button {
  font: inherit;
  padding: 0.5rem 2rem;
  cursor: pointer;
  text-transform: uppercase;
} */
</style>
<template>
  <template class="lg-scr-only">
    <header class="flex flex-row mx-4">
      <router-link to="/trails"
        ><h1 class="pt-8 text-3xl text-green-700">
          Trail Pictures
        </h1></router-link
      >
      <div id="nav" class="pt-8 text-green-700">
        <router-link to="/login" v-if="state.isLoggedIn === false"
          >Login</router-link
        >
        <router-link to="/" @click="logout" v-if="state.isLoggedIn === true"
          >Logout</router-link
        >
        <router-link to="/" class="">Home</router-link>
        <router-link to="/contact-us">Contact</router-link>
      </div>
    </header>
  </template>
  <header class="mx-4 sm-scr-only">
    <div id="nav" class="pt-none">
      <router-link to="/trails"
        ><h1 class="py-4 text-3xl text-green-700">
          Trail Pictures
        </h1></router-link
      >
      <router-link to="/login" v-if="state.isLoggedIn === false"
        >Login</router-link
      >
      <router-link to="/" @click="logoutNow" v-if="state.isLoggedIn === true"
        >Logout</router-link
      >
      <router-link to="/">Home</router-link>
      <router-link to="/contact-us">Contact</router-link>
    </div>
  </header>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import firebase from "firebase";
import { useRouter, useRoute } from "vue-router";
import useStore from "@/store";
import { getAllOfPageType, loginStatus, logout } from "../firebase.js";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { state, loginUser, logoutUser, updateLoggedIn } = useStore();
    let isAuthenticated = ref("unauthorized");
    const user = ref({});
    const logoutNow = (status) => {
      logout();
      logoutUser();
    };
    onBeforeMount(async () => {
      const status = await loginStatus();
      if (status === "authorized") {
        updateLoggedIn(true);
      }
      firebase.auth().onAuthStateChanged((userInfo) => {
        console.log("Current route: ", route.path);
        if (route.path == "/login" || route.path == "/register") {
          user.value = userInfo;
          console.log("user.value:", user.value);
          if (user.value !== null) {
            loginUser(user._value);
            console.log("user:", user._value.uid);
          }
          router.replace("/trails");
        } else {
          console.log("Current route inside: ", route.path);
        }
      });
    });
    return {
      isAuthenticated,
      loginStatus,
      logout,
      state,
      user,
      logoutUser,
      loginUser,
      logoutNow,
      updateLoggedIn,
    };
  },
};
</script>

<style>
</style>
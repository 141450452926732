import { createApp } from "vue"
import App from "./App.vue"
import BaseCard from "./components/UI/BaseCard.vue"
import BaseButton from "./components/UI/BaseButton.vue"
import router from "./router"
import "./assets/app.css"

createApp(App)
  .component("base-card", BaseCard)
  .component("base-button", BaseButton)
  .use(router)
  .mount("#app")

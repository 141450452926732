import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyDEdrkTfA1RhpAVlqkhClpoc1dnVZ9HC6E",
  authDomain: "trail-pictures.firebaseapp.com",
  databaseURL: "https://trail-pictures-default-rtdb.firebaseio.com",
  projectId: "trail-pictures",
  storageBucket: "trail-pictures.appspot.com",
  messagingSenderId: "592861565417",
  appId: "1:592861565417:web:5e4114b9bd577e47bcbb78",
}

const firebaseApp = firebase.initializeApp(config)

let isAuthenticated = "unauthorized"

// firebaseApp.auth().onAuthStateChanged((user) => {
//   if (user) {
//     isAuthenticated = "authorized"
//   } else {
//     isAuthenticated = "unauthorized"
//   }
// })

const loginStatus = () => {
  return new Promise((resolve, reject) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve("authorized")
      } else {
        resolve("unauthorized")
      }
    })
  })
}

const getAuth = async () => {
  isAuthenticated = await loginStatus()
  console.log("hello from getAuth:", isAuthenticated)
}

// getAuth()

const login = (email, password) => {
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    // .then((data) => console.log("login user data:", data))
    .catch((err) => alert(err.message))
}

const register = (email, password) => {
  firebaseApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => console.log("register user data:", user))
    .catch((err) => alert(err.message))
}

const logout = () => {
  console.log("logout triggered")
  firebaseApp
    .auth()
    .signOut()
    .then(console.log("User logged out."))
    .catch((err) => alert(err.message))
}

const getUserName = () => {
  const user = firebaseApp.auth().currentUser
  let name = ""
  if (user) {
    name = user.email.split("@")[0]
    return name
  } else {
    return name
  }
}

const db = firebaseApp.firestore()

const getAllOfPageType = async (page, type) => {
  const arrayData = []
  const snapshot = await db
    .collection("dynamic-items")
    .where("page", "==", page)
    .where("type", "==", type)
    .orderBy("sortOrder")
    .get()
  snapshot.forEach((doc) => {
    arrayData.push(doc.data())
  })
  const postData = {}
  postData[type] = arrayData
  const result = postData[type]
  return result
}

export {
  db,
  isAuthenticated,
  login,
  register,
  logout,
  getUserName,
  getAllOfPageType,
  loginStatus,
}

import { reactive, readonly, computed, watch } from "vue"

const defaultState = {
  isLoggedIn: false,
  loading: false,
  userId: null,
  userEmail: "",
  albums: [
    {
      albumId: 0,
      userId: 0,
      albumName: "",
      photos: [],
      isPublic: false,
    },
  ],
  counter: 0,
}

const getDefaultState = () => {
  if (localStorage.getItem("state") !== null) {
    return JSON.parse(localStorage.getItem("state"))
  }
  return defaultState
}

const state = reactive(getDefaultState())

const getters = {
  getCounter: () => {
    return computed(() => state.counter)
  },
  getIsLoggedIn: () => {
    return computed(() => state.isLoggedIn)
  },
}

const actions = {
  decreaseCounter() {
    state.counter--
  },
  increaseCounter() {
    state.counter++
  },
  loginUser(userObj) {
    console.log("userObj:", userObj)
    state.isLoggedIn = true
    state.userId = userObj.uid
    state.userEmail = userObj.email
  },
  logoutUser() {
    const publicKeys = ["counter"] // These are not reset to default on logout
    for (const [key, value] of Object.entries(defaultState)) {
      if (!publicKeys.includes(key)) {
        state[key] = value
      }
    }
  },
  updateLoggedIn(status) {
    state.isLoggedIn = status
  },
}

watch(
  () => state,
  () => {
    localStorage.setItem("state", JSON.stringify(state))
  },
  { deep: true }
)

export default () => {
  if (localStorage.getItem("state") === null) {
    localStorage.setItem("state", JSON.stringify(state))
  }

  return {
    state: readonly(state),
    ...getters,
    ...actions,
  }
}
